import React, { useState } from 'react';
import {
  Typography,
  Box,
  Card,
  CardContent,
  Button,
  useTheme,
  Divider,
  Avatar,
  Dialog,
  DialogContent,
  IconButton
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import CloseIcon from '@mui/icons-material/Close';
import SchoolIcon from '@mui/icons-material/School';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import LanguageIcon from '@mui/icons-material/Language';
import SettingsIcon from '@mui/icons-material/Settings';
import EditIcon from '@mui/icons-material/Edit';
import { styled, keyframes } from '@mui/material/styles';
import '../custom.css';

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const GridContainer = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '1fr',
  gap: theme.spacing(4),
  [theme.breakpoints.up('sm')]: {
    gridTemplateColumns: 'repeat(auto-fill, minmax(450px, 1fr))',
  },
}));

const AnimatedCard = styled(Card, {
  shouldForwardProp: (prop) => prop !== 'disabled'
})(({ theme, disabled }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  animation: `${fadeIn} 0.5s ease-out`,
  transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
  ...(disabled ? {
    backgroundColor: theme.palette.action.disabledBackground,
    cursor: 'not-allowed',
    '& *': {
      color: theme.palette.text.disabled,
    },
  } : {
    '&:hover': {
      transform: 'translateY(-10px)',
      boxShadow: theme.shadows[10],
    },
  }),
}));

const StyledCardContent = styled(CardContent)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  padding: theme.spacing(3),
  '&:last-child': {
    paddingBottom: theme.spacing(3),
  },
}));

const ContentWrapper = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
}));

const Header = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginBottom: theme.spacing(2),
}));

const Content = styled(Typography)(({ theme }) => ({
  flexGrow: 1,
  marginBottom: theme.spacing(2),
  color: theme.palette.text.secondary,
  lineHeight: 1.6,
  [theme.breakpoints.up('sm')]: {
    height: '120px',
  },
  overflow: 'hidden',
  display: '-webkit-box',
  '-webkit-line-clamp': 6,
  '-webkit-box-orient': 'vertical',
}));

const VideoDialog = ({ open, handleClose, videoUrl }) => {
  const { t } = useTranslation();

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="lg"
      fullWidth
    >
      <DialogContent sx={{ p: 0, position: 'relative', paddingTop: '56.25%' }}>
        <iframe
          width="100%"
          height="100%"
          src={`${videoUrl}?autoplay=1`}
          title={t('videoPlayer.title')}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
        />
        <IconButton
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: 'white',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            '&:hover': {
              backgroundColor: 'rgba(0, 0, 0, 0.7)',
            },
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogContent>
    </Dialog>
  );
};

const GuideSection = ({ title, content, videoUrl, icon, disabled = false }) => {
  const theme = useTheme();
  const [openVideo, setOpenVideo] = useState(false);
  const { t } = useTranslation();

  return (
    <AnimatedCard elevation={3} disabled={disabled}>
      <StyledCardContent>
        <ContentWrapper>
          <Header>
            <Avatar
              sx={{
                bgcolor: disabled ? theme.palette.action.disabledBackground : theme.palette.primary.main,
                mr: 2,
                width: 32,
                height: 32,
                '& .MuiSvgIcon-root': {
                  fontSize: 24,
                  color: disabled ? theme.palette.text.disabled : 'inherit',
                },
              }}
            >
              {icon}
            </Avatar>
            <Typography
              variant="h6"
              component="div"
              sx={{
                color: disabled ? theme.palette.text.disabled : theme.palette.primary.main,
                fontSize: '1.1rem',
                fontWeight: 600,
              }}
            >
              {title}
            </Typography>
          </Header>
          <Divider sx={{ mb: 2, opacity: disabled ? 0.5 : 1 }} />
          <Content variant="body2">
            {content}
          </Content>
          <Button
            variant="outlined"
            color="primary"
            startIcon={<PlayCircleOutlineIcon />}
            onClick={() => !disabled && setOpenVideo(true)}
            disabled={disabled}
            sx={{
              alignSelf: 'flex-start',
              borderRadius: '20px',
              textTransform: 'none',
              fontWeight: 'bold',
              mt: 'auto',
              opacity: disabled ? 0.5 : 1,
            }}
          >
            {t('Watch video')}
          </Button>
        </ContentWrapper>
      </StyledCardContent>
      <VideoDialog
        open={openVideo}
        handleClose={() => setOpenVideo(false)}
        videoUrl={videoUrl}
      />
    </AnimatedCard>
  );
};

function Guide() {
  const theme = useTheme();
  const { t } = useTranslation();

  const guideData = [
    {
      title: t('Produce an article from A to Z'),
      content: t('guide.sections.quickGuide'),
      videoUrl: 'https://www.youtube.com/embed/mxTrbUU4ATc?si=zQQoea5bgb87abWT',
      icon: <SchoolIcon />,
      disabled: false
    },
    {
      title: t('Standard or Advanced?'),
      content: t('guide.sections.aiModes'),
      videoUrl: 'https://www.youtube.com/embed/dQw4w9WgXcQ',
      icon: <AddCircleOutlineIcon />,
      disabled: true
    },
    {
      title: t('Create a new project'),
      content: t('Video being recorded!'),
      videoUrl: 'https://www.youtube.com/embed/dQw4w9WgXcQ',
      icon: <LanguageIcon />,
      disabled: true
    },
    {
      title: t('Add and edit data'),
      content: t('Video being recorded!'),
      videoUrl: 'https://www.youtube.com/embed/dQw4w9WgXcQ',
      icon: <SettingsIcon />,
      disabled: true
    },
    {
      title: t('Data statistics'),
      content: t('Video being recorded!'),
      videoUrl: 'https://www.youtube.com/embed/dQw4w9WgXcQ',
      icon: <EditIcon />,
      disabled: true
    },
    {
      title: t('Settings, instructions and extras'),
      content: t('Video being recorded!'),
      videoUrl: 'https://www.youtube.com/embed/dQw4w9WgXcQ',
      icon: <EditIcon />,
      disabled: true
    },
    {
      title: t('View and modify article generation'),
      content: t('Video being recorded!'),
      videoUrl: 'https://www.youtube.com/embed/dQw4w9WgXcQ',
      icon: <EditIcon />,
      disabled: true
    }
  ];

  return (
    <Box sx={{ maxWidth: 1280, margin: '0 auto', padding: 3 }}>
      <Typography className="custom-title">
        {t('guide.title')}
      </Typography>

      <Typography className="custom-subtitle">
        {t('guide.subtitle')}
      </Typography>

      <GridContainer>
        {guideData.map((section, index) => (
          <GuideSection key={index} {...section} />
        ))}
      </GridContainer>
    </Box>
  );
}

export default Guide;